<template>
  <div>
    <label class="mb-2 capitalize">{{type.replaceAll('_', ' ') }}</label>
    Frequency:
    <select v-model="frequency" class="appearance-none shadow border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
      <option value=""></option>
      <option value="daily">Daily</option>
      <option value="weekly">Weekly</option>
      <option value="monthly">Monthly</option>
    </select>
    <br><br>
    <template v-if="type === 'weight'">
      Alert:
      <input v-model="min" class="w-16 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Lost"/>
      <input v-model="max" class="w-16 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Gain"/>
      <br><br>
      Warning:
      <input v-model="min_warning" class="w-16 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Lost"/>
      <input v-model="max_warning" class="w-16 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Gain"/>
    </template>
    <template v-else>
      Alert:
      <input v-model="min" class="w-14 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Min"/>
      <input v-model="max" class="w-14 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Max"/>
      <br><br>
      Warning:
      <input v-model="min_warning" class="w-14 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Min"/>
      <input v-model="max_warning" class="w-14 shadow appearance-none border rounded py-2 px-3 mx-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Max"/>
    </template>
  </div>
</template>
<script>
import Template from "@/mixins/Template"

export default {
  name: 'AlertInputs',
  components: {Template},
  props: {
    alert: {type: Object}
  },
  watch: {
    min: function (val) {
      this.min = val;
      this.$emit("update:min", this.min);
      this.calcWarnings();
    },
    max: function (val) {
      this.max = val;
      this.$emit("update:max", this.max);
      this.calcWarnings();
    },
    min_warning: function (val) {
      this.min_warning = val;
      this.$emit("update:min_warning", this.min_warning);
    },
    max_warning: function (val) {
      this.max_warning = val;
      this.$emit("update:max_warning", this.max_warning);
    },
    frequency: function (val) {
      this.frequency = val;
      this.$emit("update:frequency", this.frequency);
    },
  },
  methods: {
    calcWarnings() {
      if (parseFloat(this.max) > parseFloat(this.min)) {
        const offset = (parseFloat(this.max) - parseFloat(this.min)) * .1;
        this.min_warning = parseFloat(this.min) + offset;
        this.max_warning = this.max - offset;
        this.$emit("update:min_warning", this.min_warning);
        this.$emit("update:max_warning", this.max_warning);
      }
    },
  },
  //{ type: "systolic", min: 95, max: 160, min_warning: 102, max_warning: 153, frequency: "daily" }
  data() {
    return {
      type: this.alert.type,
      min: this.alert.min,
      max: this.alert.max,
      min_warning: this.alert.min_warning,
      max_warning: this.alert.max_warning,
      frequency: this.alert.frequency,
    }
  },
}
</script>
